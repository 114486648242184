import React from "react";
import { Link } from "react-router-dom";

const Sidebar = () => {
  return (
    <>
      <div className="header">
        <div className="header-left">
          <Link to="/dashboard" className="logo">
            <img src="assets/img/logo.png" width={40} height={40} alt="" />
          </Link>
          <Link to="/dashboard" className="logo2">
            <img src="assets/img/logo.png" width={40} height={40} alt="" />
          </Link>
        </div>
        {/* <Link id="toggle_btn" to="#">
            <span className="bar-icon">
              <span />
              <span />
              <span />
            </span>
          </Link> */}
        <div className="page-title-box">
          <h3>ADG Online Solutions Pvt. Ltd</h3>
        </div>
        <Link id="mobile_btn" className="mobile_btn" to="#sidebar">
          <i className="fa fa-bars" />
        </Link>
        <ul className="nav user-menu">
          <li className="nav-item">
            <div className="top-nav-search">
              <Link to="" className="responsive-search">
                <i className="fa fa-search" />
              </Link>
              {/* <form action="search">
                <input
                  className="form-control"
                  type="text"
                  placeholder="Search here"
                />
                <button className="btn" type="submit">
                  <i className="fa fa-search" />
                </button>
              </form> */}
            </div>
          </li>
          <li className="nav-item dropdown has-arrow flag-nav">
            {/* <Link
              className="nav-link dropdown-toggle"
              data-bs-toggle="dropdown"
              to="#"
              role="button"
            >
              <img src="assets/img/flags/us.png" alt="" height={20} />
              <span>English</span>
            </Link> */}
            <div className="dropdown-menu dropdown-menu-right">
              <Link to="" className="dropdown-item">
                <img src="assets/img/flags/us.png" alt="" height={16} />
                English
              </Link>
              <Link to="" className="dropdown-item">
                <img src="assets/img/flags/fr.png" alt="" height={16} />
                French
              </Link>
              <Link to="" className="dropdown-item">
                <img src="assets/img/flags/es.png" alt="" height={16} />
                Spanish
              </Link>
              <Link to="" className="dropdown-item">
                <img src="assets/img/flags/de.png" alt="" height={16} />
                German
              </Link>
            </div>
          </li>
          <li className="nav-item dropdown">
            <Link
              to="#"
              className="dropdown-toggle nav-link"
              data-bs-toggle="dropdown"
            >
              <i className="fa fa-bell-o" />
              <span className="badge rounded-pill">3</span>
            </Link>
            <div className="dropdown-menu notifications">
              <div className="topnav-dropdown-header">
                <span className="notification-title">Notifications</span>
                <Link to="" className="clear-noti">
                  Clear All
                </Link>
              </div>
              <div className="noti-content">
                <ul className="notification-list">
                  <li className="notification-message">
                    <Link to="activities">
                      <div className="media d-flex">
                        <span className="avatar flex-shrink-0">
                          <img alt="" src="assets/img/profiles/avatar-02.jpg" />
                        </span>
                        <div className="media-body flex-grow-1">
                          <p className="noti-details">
                            <span className="noti-title">John Doe</span> added
                            new task
                            <span className="noti-title">
                              Patient appointment booking
                            </span>
                          </p>
                          <p className="noti-time">
                            <span className="notification-time">
                              4 mins ago
                            </span>
                          </p>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li className="notification-message">
                    <Link to="activities">
                      <div className="media d-flex">
                        <span className="avatar flex-shrink-0">
                          <img alt="" src="assets/img/profiles/avatar-03.jpg" />
                        </span>
                        <div className="media-body flex-grow-1">
                          <p className="noti-details">
                            <span className="noti-title">Tarah Shropshire</span>
                            changed the task name
                            <span className="noti-title">
                              Appointment booking with payment gateway
                            </span>
                          </p>
                          <p className="noti-time">
                            <span className="notification-time">
                              6 mins ago
                            </span>
                          </p>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li className="notification-message">
                    <Link to="activities">
                      <div className="media d-flex">
                        <span className="avatar flex-shrink-0">
                          <img alt="" src="assets/img/profiles/avatar-06.jpg" />
                        </span>
                        <div className="media-body flex-grow-1">
                          <p className="noti-details">
                            <span className="noti-title">Misty Tison</span>
                            added
                            <span className="noti-title">Domenic Houston</span>
                            and
                            <span className="noti-title">Claire Mapes</span>
                            to project
                            <span className="noti-title">
                              Doctor available module
                            </span>
                          </p>
                          <p className="noti-time">
                            <span className="notification-time">
                              8 mins ago
                            </span>
                          </p>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li className="notification-message">
                    <Link to="activities">
                      <div className="media d-flex">
                        <span className="avatar flex-shrink-0">
                          <img alt="" src="assets/img/profiles/avatar-17.jpg" />
                        </span>
                        <div className="media-body flex-grow-1">
                          <p className="noti-details">
                            <span className="noti-title">Rolland Webber</span>
                            completed task
                            <span className="noti-title">
                              Patient and Doctor video conferencing
                            </span>
                          </p>
                          <p className="noti-time">
                            <span className="notification-time">
                              12 mins ago
                            </span>
                          </p>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li className="notification-message">
                    <Link to="activities">
                      <div className="media d-flex">
                        <span className="avatar flex-shrink-0">
                          <img alt="" src="assets/img/profiles/avatar-13.jpg" />
                        </span>
                        <div className="media-body flex-grow-1">
                          <p className="noti-details">
                            <span className="noti-title">Bernardo Galaviz</span>
                            added new task
                            <span className="noti-title">
                              Private chat module
                            </span>
                          </p>
                          <p className="noti-time">
                            <span className="notification-time">
                              2 days ago
                            </span>
                          </p>
                        </div>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="topnav-dropdown-footer">
                <Link to="activities">View all Notifications</Link>
              </div>
            </div>
          </li>
          <li className="nav-item dropdown">
            <Link
              to="#"
              className="dropdown-toggle nav-link"
              data-bs-toggle="dropdown"
            >
              <i className="fa fa-comment-o" />
              <span className="badge rounded-pill">8</span>
            </Link>
            <div className="dropdown-menu notifications">
              <div className="topnav-dropdown-header">
                <span className="notification-title">Messages</span>
                <Link to="" className="clear-noti">
                  Clear All
                </Link>
              </div>
              <div className="noti-content">
                <ul className="notification-list">
                  <li className="notification-message">
                    <Link to="chat">
                      <div className="list-item">
                        <div className="list-left">
                          <span className="avatar">
                            <img
                              alt=""
                              src="assets/img/profiles/avatar-09.jpg"
                            />
                          </span>
                        </div>
                        <div className="list-body">
                          <span className="message-author">Richard Miles</span>
                          <span className="message-time">12:28 AM</span>
                          <div className="clearfix" />
                          <span className="message-content">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                          </span>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li className="notification-message">
                    <Link to="chat">
                      <div className="list-item">
                        <div className="list-left">
                          <span className="avatar">
                            <img
                              alt=""
                              src="assets/img/profiles/avatar-02.jpg"
                            />
                          </span>
                        </div>
                        <div className="list-body">
                          <span className="message-author">John Doe</span>
                          <span className="message-time">6 Mar</span>
                          <div className="clearfix" />
                          <span className="message-content">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                          </span>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li className="notification-message">
                    <Link to="chat">
                      <div className="list-item">
                        <div className="list-left">
                          <span className="avatar">
                            <img
                              alt=""
                              src="assets/img/profiles/avatar-03.jpg"
                            />
                          </span>
                        </div>
                        <div className="list-body">
                          <span className="message-author">
                            Tarah Shropshire
                          </span>
                          <span className="message-time">5 Mar</span>
                          <div className="clearfix" />
                          <span className="message-content">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                          </span>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li className="notification-message">
                    <Link to="chat">
                      <div className="list-item">
                        <div className="list-left">
                          <span className="avatar">
                            <img
                              alt=""
                              src="assets/img/profiles/avatar-05.jpg"
                            />
                          </span>
                        </div>
                        <div className="list-body">
                          <span className="message-author">Mike Litorus</span>
                          <span className="message-time">3 Mar</span>
                          <div className="clearfix" />
                          <span className="message-content">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                          </span>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li className="notification-message">
                    <Link to="chat">
                      <div className="list-item">
                        <div className="list-left">
                          <span className="avatar">
                            <img
                              alt=""
                              src="assets/img/profiles/avatar-08.jpg"
                            />
                          </span>
                        </div>
                        <div className="list-body">
                          <span className="message-author">
                            Catherine Manseau
                          </span>
                          <span className="message-time">27 Feb</span>
                          <div className="clearfix" />
                          <span className="message-content">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                          </span>
                        </div>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="topnav-dropdown-footer">
                <Link to="chat">View all Messages</Link>
              </div>
            </div>
          </li>
          <li className="nav-item dropdown has-arrow main-drop">
            <Link
              to="#"
              className="dropdown-toggle nav-link"
              data-bs-toggle="dropdown"
            >
              <span className="user-img">
                <img src="assets/img/profiles/avatar-21.jpg" alt="" />
                <span className="status online" />
              </span>
              <span>User</span>
            </Link>
            <div className="dropdown-menu">
              <Link className="dropdown-item" to="/profile">
                My Profile
              </Link>
              <Link className="dropdown-item" to="settings">
                Settings
              </Link>
              <Link className="dropdown-item" to="index">
                Logout
              </Link>
            </div>
          </li>
        </ul>
        <div className="dropdown mobile-user-menu">
          <Link
            to="#"
            className="nav-link dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i className="fa fa-ellipsis-v" />
          </Link>
          <div className="dropdown-menu dropdown-menu-right">
            <Link className="dropdown-item" to="/profile">
              My Profile
            </Link>
            <Link className="dropdown-item" to="settings">
              Settings
            </Link>
            <Link className="dropdown-item" to="index">
              Logout
            </Link>
          </div>
        </div>
      </div>
      <div className="sidebar" id="sidebar">
        <div className="sidebar-inner slimscroll">
          <div id="sidebar-menu" className="sidebar-menu">
            <ul className="sidebar-vertical">
              <li className="">
                <Link to="/dashboard">
                  <i className="la la-dashboard" />
                  <span>Dashboard</span>
                </Link>
              </li>

              <li className="menu-title">
                <span>Employees</span>
              </li>
              <li className="submenu">
                <Link to="#">
                  <i className="la la-user" /> <span> Employees</span>
                  <span className="menu-arrow" />
                </Link>
                <ul style={{ display: "none" }}>
                  <li>
                    <Link to="employees">Employee List</Link>
                  </li>

                  {/* <li>
                    <Link to="leaves">
                      Employee Performance
                      <span className="badge rounded-pill bg-primary float-end">
                        1
                      </span> 
                    </Link>
                  </li> */}
                </ul>
              </li>
              <li className="menu-title">
                <span>HR</span>
              </li>
              <li className="">
                <Link to="/leave">
                  <i className="la la-files-o" />
                  <span> Leave Management </span>
                  {/* <span className="menu-arrow" /> */}
                </Link>
                {/* <ul style={{ display: "none" }}>
                  <li>
                    <Link to="estimates">Add Leave Type</Link>
                  </li>
                  <li>
                    <Link to="estimates">Leave Application</Link>
                  </li>
                </ul> */}
              </li>
              <li className="">
                <Link to="/attendance">
                  <i className="la la-files-o" /> <span> Attendance </span>
                  {/* <span className="menu-arrow" /> */}
                </Link>
                {/* <ul style={{ display: "none" }}>
                  <li>
                    <Link to="/daily-attendance">Daily Attendance</Link>
                  </li>
                </ul> */}
              </li>
              <li className="submenu">
                <Link to="#">
                  <i className="la la-money" /> <span> Payroll </span>
                  <span className="menu-arrow" />
                </Link>
                <ul style={{ display: "none" }}>
                  <li>
                    <Link to="salary"> Salary Generate</Link>
                  </li>
                  <li>
                    <Link to="salary-view"> Salary List </Link>
                  </li>
                  <li>
                    <Link to="payroll-items"> Manage Employee Salary</Link>
                  </li>
                </ul>
              </li>
              <li className="submenu">
                <Link to="#">
                  <i className="la la-money" />
                  <span> Manpower Registration </span>
                  <span className="menu-arrow" />
                </Link>
                <ul style={{ display: "none" }}>
                  <li>
                    <Link to="#">
                      <i className="la la-money" /> <span> ADG </span>
                      <span className="menu-arrow" />
                    </Link>
                    <ul style={{ display: "none" }}>
                      <li>
                        <Link to="/adg-emp-list">Emp. List</Link>
                      </li>
                      <li>
                        <Link to="/adg-emp-epartment"> Department</Link>
                      </li>
                      <li>
                        <Link to="/adg-project">Project</Link>
                      </li>
                      <li>
                        <Link to="/emp-payslip">Payslip</Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link to="#">
                      <i className="la la-money" /> <span> MNN </span>
                      <span className="menu-arrow" />
                    </Link>
                    <ul style={{ display: "none" }}>
                      <li>
                        <Link to="/mnn-emp-list">Emp. List</Link>
                      </li>
                      <li>
                        <Link to="/mnn-emp-epartment"> Department</Link>
                      </li>
                      <li>
                        <Link to="/mnn-project">Project</Link>
                      </li>
                      <li>
                        <Link to="payroll-items">Payslip</Link>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li className="submenu">
                <Link to="#">
                  <i className="la la-money" />
                  <span>Setup Rules</span>
                  <span className="menu-arrow" />
                </Link>
                <ul style={{ display: "none" }}>
                  <li>
                    <Link to="/user#">
                      <i className="la la-money" /> <span>User</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <i className="la la-money" /> <span>Department</span>
                      <span className="menu-arrow" />
                    </Link>
                    <ul style={{ display: "none" }}>
                      <li>
                        <Link to="/department-details">Department Details</Link>
                      </li>
                      {/* <li>
                        <Link to="/add-sub-department">Add Sub Department</Link>
                      </li>
                      <li>
                        <Link to="/manage-sub-department">Manage Sub Department</Link>
                      </li> */}
                      <li>
                        <Link to="/designation">Designation</Link>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li className="submenu">
                <Link to="#">
                  <i className="la la-money" />
                  <span> Setting</span>
                  <span className="menu-arrow" />
                </Link>
                <ul style={{ display: "none" }}>
                  <li>
                    <Link to="#">
                      <i className="la la-money" /> <span>User</span>
                      <span className="menu-arrow" />
                    </Link>
                    <ul style={{ display: "none" }}>
                      <li>
                        <Link to="/add-user">Add User</Link>
                      </li>
                      <li>
                        <Link to="/user-list">User List</Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link to="#">
                      <i className="la la-money" /> <span>Role Permission</span>
                      <span className="menu-arrow" />
                    </Link>
                    <ul style={{ display: "none" }}>
                      <li>
                        <Link to="/add-role">Add Role</Link>
                      </li>
                      <li>
                        <Link to="/role-list">Role List</Link>
                      </li>
                      <li>
                        <Link to="/user-access-role">User Access Role</Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link to="/leave-holiday">
                      <i className="la la-money" /> <span>Leave/Holiday</span>
                      <span className="menu-arrow" />
                    </Link>
                    <ul style={{ display: "none" }}>
                      <li>
                        <Link to="/weekly-holiday">Weekly Holiday</Link>
                      </li>
                      <li>
                        <Link to="/holiday">Holiday</Link>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>

              <li>
                <Link to="/policies">
                  <i className="la la-file-pdf-o" /> <span>Policies</span>
                </Link>
              </li>
              <li>
                <Link to="/expense-management">
                  <i className="la la-file-pdf-o" />
                  <span>Expense Management</span>
                </Link>
              </li>
              <li>
                <Link to="/tour-management">
                  <i className="la la-file-pdf-o" />
                  <span>Tour Management</span>
                </Link>
              </li>
              <li className="menu-title">
                <span>Administration</span>
              </li>
              <li>
                <Link to="/assets">
                  <i className="la la-object-ungroup" /> <span>Assets</span>
                </Link>
              </li>
              <li>
                <Link to="/Jobs">
                  <i className="la la-briefcase" /> <span> Jobs </span>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;

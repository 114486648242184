import { LOGIN } from "../type";

const initialState = { data: null, loading: false, error: null };

const loginReducer = (state = initialState, action) => {
  
  const { type, payload } = action;
  switch (type) {
    case LOGIN.FETCH: {
      return { ...state, loading: true };
    }
    case LOGIN.SUCCESS: {
      const data = payload;
      return { ...state, loading: false, data };
    }
    case LOGIN.ERROR: {
      const message = payload;
      return { ...state, loading: false, message };
    }
    case LOGIN.RESET: {
      return { ...initialState };
    }
    default:
      return state;
  }
};
export default loginReducer;
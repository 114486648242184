import axios from "axios";
export const LoginApi = async (postData) => {
  return new Promise((resolve, reject) => {
    axios({
      method: "POST",
      url: `https://demo.hrms.adgonline.in/api/public/api/login`,
      data: postData,
    })
      .then((responce) => {
        resolve(responce);
      })
      .catch((error) => {
        if (error) {
          reject(error.message);
        }
      });
  });
};

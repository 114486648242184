import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import loginAction from "../../redux/actions/login.action";
function Login() {
  const [user, setUser] = React.useState({
    email: "",
    password: "",
  });
  const dispatch = useDispatch();
  // const loginState = useSelector((state) => {
  //   return state.login;
  // });
  // console.log(loginState);
  const OnChange = (event) => {
    setUser({ ...user, [event.target.name]: event.target.value });
  };
  const handleOnSubmit = (event) => {
    // console.log(user)
    event.preventDefault();
    console.log("first");
    dispatch(loginAction.login(user))
  };
  return (
    <>
      <div className="account-page">
        <div className="main-wrapper">
          <div className="account-content">
            <div className="container">
              <div className="account-logo">
                <Link to={"/"}>
                  <img
                    src="assets/img/logo.png"
                    alt="ADG Online Solutions Pvt Ltd"
                  />
                </Link>
              </div>

              <div className="account-box">
                <div className="account-wrapper">
                  <h3 className="account-title">Login</h3>
                  <p className="account-subtitle">Access to our dashboard</p>

                  {/* <form> */}
                    <div className="form-group">
                      <label>Email Address</label>
                      <input
                        className="form-control"
                        type="text"
                        value={user.email}
                        name="email"
                        onChange={(event) => OnChange(event)}
                      />
                    </div>
                    <div className="form-group">
                      <div className="row">
                        <div className="col">
                          <label>Password</label>
                        </div>
                        <div className="col-auto">
                          <Link className="text-muted" to="/forgot-password">
                            Forgot password?
                          </Link>
                        </div>
                      </div>
                      <div className="position-relative">
                        <input
                          className="form-control"
                          type="password"
                          value={user.password}
                          name="password"
                          onChange={(event) => OnChange(event)}
                        />
                        <span
                          className="fa fa-eye-slash"
                          id="toggle-password"
                        ></span>
                      </div>
                    </div>
                    <div className="form-group text-center">
                      <button
                        className="btn btn-primary account-btn"
                        type="submit"
                        onClick={handleOnSubmit}
                      >
                        Login
                      </button>
                    </div>
                    <div className="account-footer">
                      <p>
                        Don't have an account yet?
                        <Link to="/register">Register</Link>
                      </p>
                    </div>
                  {/* </form> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;

import React from "react";
import { Link } from "react-router-dom";

function Error404() {
  return (
    <>
      <div className="error-page">
        <div className="main-wrapper">
          <div className="error-box">
            <h1>404</h1>
            <h3>
              <i className="fa fa-warning"></i> Oops! Page not found!
            </h3>
            <p>The page you requested was not found.</p>
            <Link to={"/"} className="btn btn-custom">
              Back to Home
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default Error404;

import React from "react";
import DashboardPanal from "../../componets/dashboard/DashboardPanal";

const Dashboard = () => {
  return (
    <>
        <DashboardPanal />
    </>
  );
};

export default Dashboard;

import "./App.css";
import { Route, Routes, useLocation } from "react-router-dom";
import Login from "./views/auth/Login";
import Error404 from "./views/pages/error/Error404";
import Error500 from "./views/pages/error/Error500";
import React from "react";
import routes from "./routes";
import Sidebar from "./common/Sidebar";
function App() {
  const loaction = useLocation();
  return (
    <>
      {loaction.pathname != "/" && <Sidebar />}
      {/* {loaction.pathname != "*" && <Error404 />} */}
      <Routes>
        <Route exact path="/" name="Login Page" element={<Login />} />
        <Route exact path="*" name="Error Page" element={<Error404 />} />
        <Route exact path="/404" name="Error Page" element={<Error404 />} />
        <Route exact path="/500" name="Error Page" element={<Error500 />} />
        {routes.map((route, i) => (
          <Route
          key={i}
            exact={route.exact}
            path={route.path}
            name={route.name}
            element={route.element}
          />
        ))}
      </Routes>
    </>
  );
}

export default App;

import React from 'react'

const Leave = () => {
  return (
    <div>
      Leave
    </div>
  )
}

export default Leave

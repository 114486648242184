import { call, put, takeEvery } from "redux-saga/effects";
import { loginSuccess, loginError } from "../actions/login.action";
import { LOGIN } from "../type";
import { LoginApi } from "../services/index";
function* fetchLogin({ payload }) {
  try {
    const loginData = yield call(LoginApi, payload);
    yield put(loginSuccess(loginData));
  } catch (error) {
    yield put(loginError(error));
  }
}
export default function* LoginSaga() {
  yield takeEvery(LOGIN.FETCH, fetchLogin);
}

import { LOGIN } from "../type";

export function login(data) {
  return {
    type: LOGIN.FETCH,
    payload: data,
  };
}
export function loginSuccess(data) {
  return {
    type: LOGIN.SUCCESS,
    payload: data,
  };
}
export function loginError(message) {
  return {
    type: LOGIN.ERROR,
    payload: message,
  };
}
export function loginReset() {
  return {
    type: LOGIN.RESET,
  };
}

const loginAction = {
  login,
  loginSuccess,
  loginError,
  loginReset,
};
export default loginAction;

import React from 'react'
import Attendance  from '../views/modules/attendance/Attendance';
import Dashboard  from '../views/dashboard';
import Leave  from '../views/modules/leave-managment/Leave';
import Employees  from '../views/modules/employees/Employees';
import Profile  from '../views/profile';
import AddUser from '../views/modules/adg/AddUser';
const routes = [
    { path: "/dashboard", exact: true, name: "Home", element:<Dashboard/>},
    { path: "/leave", exact: true, name: "Leave", element:<Leave/>},
    { path: "/employees", exact: true, name: "Employees", element:<Employees/>},
    { path: "/attendance", exact: true, name: "Attendance", element:<Attendance/>},
    { path: "/profile", exact: true, name: "Attendance", element:<Profile/>},
    { path: "/add-user", exact: true, name: "Attendance", element:<AddUser/>},
];
export default routes